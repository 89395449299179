import React from 'react';
import SocialLinks from '../components/SocialLinks';
import { GatsbyImage } from "gatsby-plugin-image"

import '../assets/sass/_author.scss';

const days = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado',
];

const translateWeekdays = (d) => {
  if(d.length > 1 && days.indexOf(d[d.length -1])  - days.indexOf(d[0]) === d.length - 1){
    return `${d[0]} a ${d[d.length - 1]}`;
  } 
  return d.join(', ');
}

const Author = ({
  name,
  specialities = [],
  picture,
  description = '',
  timetable,
  customer,
  servicesdescription,
  socialmedias,
  ...props
}) => {
  let timetableDisp = {};
  let weekdays = {};
  timetable.forEach((t) => {
    let k = `${t.starttime} às ${t.endtime}`;
    if (weekdays[t.day]) {
      weekdays[t.day].push(k);
    } else {
      weekdays[t.day] = [k];
    }
  });

  Object.keys(weekdays).forEach((t) => {
    let k = weekdays[t].join(weekdays[t].length > 2?",": " e das ");
    if (timetableDisp[k]) {
      timetableDisp[k].push(t);
    } else {
      timetableDisp[k] = [t];
    }
  });
  return (
    <section className="authorContainer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="card container-fluid hovercard">
              <div className="avatar">
                <GatsbyImage
                  className={'image'}
                  alt={
                    specialities &&
                    specialities.map((s) => s.speciality).join(' // ')
                  }
                  image={picture.gatsbyImageData}
                />
              </div>

              <div className="info">
                <div className="title">{name}</div>
                <div className="bottom">
                  {customer && socialmedias && (
                    <SocialLinks
                      links={socialmedias.map((s) => ({
                        type: s?.platform,
                        url: s?.link?.url,
                      }))}
                    />
                  )}
                </div>
                {props.book && (
                  <div className="bottom">
                    {customer && (
                      <a
                        href=" "
                        onClick={(e) => {
                          var element = document.querySelector(
                            '#book-appoitment'
                          );

                          // smooth scroll to element and align it at the bottom
                          element &&
                            element.scrollIntoView({
                              behavior: 'smooth',
                              block: 'end',
                            });
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        className="btn btn-primary btn-cta"
                      >
                        Marcar Consulta
                      </a>
                    )}
                  </div>
                )}
                <div className="row d-flex justify-content-center">
                  <div className="col content text-center subtitle pt-5 pb-5">
                    {specialities &&
                      specialities.map((v, k) => (
                        <>
                          {k === 0 ? '' : ', '}
                          <span key={`sp-${k}`}>{v.speciality}</span>
                        </>
                      ))}
                  </div>
                  </div>
                        <div className="row d-flex justify-content-center">
                  <div className="col content text-left">
                    {description && description.html && (
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: description.html,
                        }}
                      ></div>
                    )}
                    {servicesdescription &&
                      servicesdescription.html &&
                      servicesdescription.html !== '<p></p>' && (
                        <>
                          <h2 className=" pt-3 pb-2 text-primary text-left">
                            Descrição dos Serviços
                          </h2>
                          <div
                            className="desc text-left"
                            dangerouslySetInnerHTML={{
                              __html: servicesdescription.html,
                            }}
                          ></div>
                        </>
                      )}
                  </div>
                </div>
              </div>

              {customer && timetable && timetable.length > 0 && (
                <div className="subtitle pt-3 text-primary">
                  <h2>Horário das consultas:</h2>
                  {Object.keys(timetableDisp).map((t, i) => (
                    <span key={i + 'timetable'}>
                      <p className={'text-color'} key={`${i}-day`}>
                        {translateWeekdays(timetableDisp[t])}
                      </p>
                      <h4 key={i}>{t}</h4>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Author;
