import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Fade from 'react-reveal/Fade';
  import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Footer from '../components/Footer';
import SimilarArticles from '../components/SimilarArticles';
import BookAppointment from '../components/BookAppointment';

import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Author from '../components/Author';
import Seo from '../components/Seo';
import config from '../../config';
import usePreviewData from '../utils/usePreviewData';

const AuthorTemplate = ({ data, pageContext, location, ...props }) => {
      const liveData = usePreviewData(data);
  if (!(liveData?.prismicAuthor?.data)) return <></>;
  let {
    customer,
    picture,
    description,
    specialities,
    name,
    internallink,
    book,
    timetable,
    calendarid,
    holidays,
    notificationsemail,
    seokeywords
  } = liveData.prismicAuthor.data;
  if (!liveData.prismicAuthor.data) return <></>;
  return (
    <Layout
      seoComp={
        !customer ? (
          <Seo type={`author`} title={name} author={name} />
        ) : (
          <Seo
            type={`author`}
            image={picture.url}
            description={description.text}
            keywords={specialities
              .map((s) => s.speciality + " em Lisboa")
              .concat([
                seokeywords,
                ...['consultas em Lisboa', 'Lisboa', name],
              ])}
            title={name}
            author={name}
            site={`${config.url}/${internallink}`}
          />
        )
      }
    >
      <Header showHeader={false} subtitle="" title={pageContext.name} />

      <div className="container-fluid site-container blog-post gray-100-bg">
        <div className="text-center pt-5">
          <Fade>
            <Author {...liveData.prismicAuthor.data} />
          </Fade>
          {book && !!customer && (
            <Fade bottom>
              <BookAppointment
                timetable={timetable}
                calendarid={calendarid}
                bookFor={name}
                notificationsemail={
                  notificationsemail ? notificationsemail : undefined
                }
                holidays={holidays.map((h) =>
                  new Date(h.date).toLocaleDateString()
                )}
              />
            </Fade>
          )}
        </div>
      </div>
      <div className="container site-container pt0 pb-0 mt-0 mb-0">
        <div className="row">
          <div className="col-12">
            <SimilarArticles
              title={`Artigos escritos por ${name}`}
              author={name}
            />
          </div>
        </div>
      </div>

      {/* <Pagination currentPage={props.pageContext.currentPage} totalPages={props.pageContext.numPages} uri="/blog" /> */}
      <SocialLinks />
      <Footer />
    </Layout>
  );
};


export default AuthorTemplate;

export const pageQuery = graphql`
  query AuthorsQuery($id: String!) {
    prismicAuthor(uid: { eq: $id }) {
      data {
        name
        listed
        customer
        internallink

        calendarid
        specialities {
          speciality
        }
        holidays {
          date
        }
        socialmedias {
          platform
          link {
            link_type
            url
          }
        }
        notificationsemail
        timetable {
          day
          starttime
          endtime
          duration
          firstduration
        }
        book
        picture {
          url
          gatsbyImageData
        }
        seokeywords
        specialities {
          speciality
        }
        description {
          html
          text
        }
        servicesdescription {
          html
          text
        }
      }
    }
  }
`;
